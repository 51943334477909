/*
* jquery-match-height 0.7.0 by @liabru
* http://brm.io/jquery-match-height/
* License MIT
*/
!function(t){"use strict";"function"==typeof define&&define.amd?define(["jquery"],t):"undefined"!=typeof module&&module.exports?module.exports=t(require("jquery")):t(jQuery)}(function(t){var e=-1,o=-1,i=function(t){return parseFloat(t)||0},a=function(e){var o=1,a=t(e),n=null,r=[];return a.each(function(){var e=t(this),a=e.offset().top-i(e.css("margin-top")),s=r.length>0?r[r.length-1]:null;null===s?r.push(e):Math.floor(Math.abs(n-a))<=o?r[r.length-1]=s.add(e):r.push(e),n=a}),r},n=function(e){var o={
byRow:!0,property:"height",target:null,remove:!1};return"object"==typeof e?t.extend(o,e):("boolean"==typeof e?o.byRow=e:"remove"===e&&(o.remove=!0),o)},r=t.fn.matchHeight=function(e){var o=n(e);if(o.remove){var i=this;return this.css(o.property,""),t.each(r._groups,function(t,e){e.elements=e.elements.not(i)}),this}return this.length<=1&&!o.target?this:(r._groups.push({elements:this,options:o}),r._apply(this,o),this)};r.version="0.7.0",r._groups=[],r._throttle=80,r._maintainScroll=!1,r._beforeUpdate=null,
r._afterUpdate=null,r._rows=a,r._parse=i,r._parseOptions=n,r._apply=function(e,o){var s=n(o),h=t(e),l=[h],c=t(window).scrollTop(),p=t("html").outerHeight(!0),d=h.parents().filter(":hidden");return d.each(function(){var e=t(this);e.data("style-cache",e.attr("style"))}),d.css("display","block"),s.byRow&&!s.target&&(h.each(function(){var e=t(this),o=e.css("display");"inline-block"!==o&&"flex"!==o&&"inline-flex"!==o&&(o="block"),e.data("style-cache",e.attr("style")),e.css({display:o,"padding-top":"0",
"padding-bottom":"0","margin-top":"0","margin-bottom":"0","border-top-width":"0","border-bottom-width":"0",height:"100px",overflow:"hidden"})}),l=a(h),h.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||"")})),t.each(l,function(e,o){var a=t(o),n=0;if(s.target)n=s.target.outerHeight(!1);else{if(s.byRow&&a.length<=1)return void a.css(s.property,"");a.each(function(){var e=t(this),o=e.attr("style"),i=e.css("display");"inline-block"!==i&&"flex"!==i&&"inline-flex"!==i&&(i="block");var a={
display:i};a[s.property]="",e.css(a),e.outerHeight(!1)>n&&(n=e.outerHeight(!1)),o?e.attr("style",o):e.css("display","")})}a.each(function(){var e=t(this),o=0;s.target&&e.is(s.target)||("border-box"!==e.css("box-sizing")&&(o+=i(e.css("border-top-width"))+i(e.css("border-bottom-width")),o+=i(e.css("padding-top"))+i(e.css("padding-bottom"))),e.css(s.property,n-o+"px"))})}),d.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||null)}),r._maintainScroll&&t(window).scrollTop(c/p*t("html").outerHeight(!0)),
this},r._applyDataApi=function(){var e={};t("[data-match-height], [data-mh]").each(function(){var o=t(this),i=o.attr("data-mh")||o.attr("data-match-height");i in e?e[i]=e[i].add(o):e[i]=o}),t.each(e,function(){this.matchHeight(!0)})};var s=function(e){r._beforeUpdate&&r._beforeUpdate(e,r._groups),t.each(r._groups,function(){r._apply(this.elements,this.options)}),r._afterUpdate&&r._afterUpdate(e,r._groups)};r._update=function(i,a){if(a&&"resize"===a.type){var n=t(window).width();if(n===e)return;e=n;
}i?-1===o&&(o=setTimeout(function(){s(a),o=-1},r._throttle)):s(a)},t(r._applyDataApi),t(window).bind("load",function(t){r._update(!1,t)}),t(window).bind("resize orientationchange",function(t){r._update(!0,t)})});


/**
 *
 * Custom function
 *
 */ 

jQuery(function(){

	try {
		var box = jQuery('#booking-section');
		if( box.length < 1 ) return;

		var boxPosition = box.offset().top;

		// su mobile facciamo in modo di aggiungere la classe "fixed"
		// appena si scrolla e non dopo un pò
		// perchè la CTA del booking mobile è ancorata nella parte inferiore dello schermo
		if( document.querySelector('body').classList.contains('is-mobile')) {
			boxPosition = 0;
		}

		jQuery(window).on('scroll', function(){
			
			var scrollPosition = jQuery(document).scrollTop();
			if (scrollPosition > boxPosition ) {
			
				jQuery('#booking-section').addClass('fixed');	
			
			} else {
				
				jQuery('#booking-section').removeClass('fixed');
			}
		});
	} catch(e) {
		console.log('boxPosition', e);
	}

});


(function($) {

	
	
	
	$('.camera-block-inner').matchHeight();
	$('.box-offer').matchHeight();
	$('.pacchetto').matchHeight();
	$('.pacchetto h3').matchHeight();
	
	
	
	function translateCountdown( langCountdown ) {

        countdown.resetLabels();
        
        if ( langCountdown == 'it' ) {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if ( langCountdown == 'de' ) {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if ( langCountdown == 'fr' ) {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

	function edtCountdown(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
            
		    var dataInizio = "";
		    var questo = jQuery(this);
		    jQuery.post(
			    
			    url.ajax_url, 
			    {
			        'action': 'edita_ajax_get_start_countdown_date',
			        'offerId':   questo.data('offerid'),			        
			    }, 
			    
			    function(response){
				    
					setInterval(function() {
                        stampaCountdown( questo, response );

                    }, 3600);
			    }
			);
	    });
	}

	function stampaCountdown( item, response ){

		try {
			if( typeof('moment') != "function") return;
			
			jQuery( item ).find( '.countdown' ).empty().append(
				moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
			);
		} catch(e) {
			console.log('stampaCountdown', e);
		}
	} 
	
	
	
    $(document).ready(function() {

        
		edtCountdown();
		
		// setto lingua in inglese per booking olandese perchè mmone non supporta l'olandese
		$('.efp-booking-layout').each( function(){

			var _this = $(this);
			var iLang = $('[name="lg"]', _this);

			if( iLang.length > 0 && iLang.val() == "nl") {
				iLang.val('en');
			}
		});
        
        //! bootstrap gallery filter
		$('.gallery-wrapper').mixItUp();
	
		$('.gallery-filter-buttons .filter').each( function(){
			var _this = jQuery(this);
	
			$('.badge', _this).text( $('.gallery-wrapper '+_this.data('filter')).length );
		});
		
		
		//note(@duncanmid): accordion
        if( $('.accordion-list dt')[0] ) {
	        
	        $('.accordion-list dt').click( function() {
		       
		       var $this = $(this);
		       
		       if( $this.hasClass('open') ) {
			      
			      $this.removeClass('open');
			       
			       $this.next('dd').fadeOut('fast');
			      
			   } else {
			       
			       $('.accordion-list dt').removeClass('open');
			       $('.accordion-list dd').fadeOut('fast');
			       
			       $this.addClass('open');
			       
			       $this.next('dd').fadeIn('fast');
			   }
		        
	        });
        }
        
        
        $('#desktop-menu-toggle').click( function() {
	       	
	       	$('#desktop-menu').fadeIn(); 
        });
        
        $('#desktop-menu-close').click( function() {
	       
	       $('#desktop-menu').fadeOut(); 
        });
        
    });


    $(window).load(function() {

		// bugfix mmone booking
		// il widget del booking di MMONE (.js) carica a sua volta il CSS
		// https://reservation.cmsone.it/booking/externalbooking/css/standard.min.css
		// con regole !important sul posizionamento del datepicker jquery
		// questo crea problemi sul calendario dei nostri form e l'unico modo per aggirare il problema
		// è sostituire in javascript l'url di quel CSS con una versione nostra locale dove possiamo modificare
		// quello che vogliamo
		$('link').each(function(){

			if( typeof(edt_site_config) == "undefined" || typeof(edt_site_config.theme_directory) == "undefined" || edt_site_config.theme_directory == "" ) {
				return false;
			}

			var thisCSS = $(this);
			var thisCSS_check = thisCSS.attr('href').search('reservation.cmsone.it/booking/externalbooking/css/standard.min.css');
			if( thisCSS_check >= 0 ) {

				thisCSS.attr('href', edt_site_config.theme_directory + '/css/mmone-booking.css' );
				console.log(thisCSS.attr('href'));
				// abbiamo individuato il CSS di mmone e fatto quello che ci serve
				// possiamo uscire dal "each"
				return false;
			}
		});
    });

    $(window).resize(function() {


    });

})(jQuery);
